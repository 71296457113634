import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';
import rightImage from '../../../assets/landingPageRightImage.jpg';
import css from './SectionHero.module.css';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.secondLinetitle" />
        </h1>
        <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.thirdLinetitle" />
        </h1>
        <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        {/* <NamedLink
          name="SearchPage"
          className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
        >
          <FormattedMessage id="SectionHero.browseButton" />
        </NamedLink> */}
        <div className={css.mainNumberDetails}>
          <div className={css.numberDetails}>
            <span className={css.number}>
              <FormattedMessage id="SectionHero.productNumber" />
            </span>
            <span>
              <FormattedMessage id="SectionHero.productFirstMessage" />
            </span>
            <span>
              <FormattedMessage id="SectionHero.productSecondessage" />
            </span>
          </div>
          <div className={css.numberDetails}>
            <span className={css.number}>
              <FormattedMessage id="SectionHero.customerNumber" />
            </span>
            <span>
              <FormattedMessage id="SectionHero.customerFirstMessage" />
            </span>
            <span>
              <FormattedMessage id="SectionHero.customerSecondMessage" />
            </span>
          </div>
          {/* <div className={css.numberDetails}>
            <span className={css.number}>
              <FormattedMessage id="SectionHero.anotherCustomerNumber" />
            </span>
            <span>
              <FormattedMessage id="SectionHero.anotherCustomerFirstMessage" />
            </span>
            <span>
              <FormattedMessage id="SectionHero.anotherCustomerSecondMessage" />
            </span>
          </div> */}
        </div>
      </div>
      <div className={css.contentandimage}>
        <img src={rightImage} alt="RightImage" />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
